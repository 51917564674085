// Theme Colors/Branding
$brand-primary: #75c68a;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #C31A8A;
$dark: #151c1c;
$light: #C1E0CA;
$medium: #192828;
$inactive: #8c8c8c;
$gray: #888;
$white: #effff4;
$accent: #0de0d2;
$accent-2: #ff00e1;
$body-font: #222;
$error: #d81e00;

// Update BS colors
$primary: $brand-primary;
$secondary: $brand-secondary;
$info: #9CCC50;