// Container Fluid Adjustments
.container-fluid {
  padding-left: 25px;
  padding-right: 25px;
  @include media-breakpoint-up(xl) {
    padding-left: 125px;
    padding-right: 125px;
  }
  @include media-breakpoint-up(xxxl) {
    padding-left: 200px;
    padding-right: 200px;
  }
  @media only screen and (min-width:2400px) {
    padding-left:23%;
    padding-right:23%;
  }
  @media only screen and (min-width:3000px) {
    padding-left:25%;
    padding-right:25%;
  }
}
.container {
  max-width:1280px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left:auto;
  margin-right:auto;
}
