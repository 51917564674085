.rpb-accordion {
  .accordion-item {
    background: $medium;
    .accordion-body {
      p {
        &:last-of-type {
          margin-bottom:0;
        }
      }
    }
    .accordion-button {
      background-color: transparent;
      color: inherit;
    }
    .accordion-button:not(.collapsed) {
      color:$brand-primary;
      background-color: $light;
      box-shadow: unset !important;
    }
    .accordion-button::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }
  }
}