/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* Gravity Forms Styling */

.gform_legacy_markup_wrapper textarea.large {
  @include media-breakpoint-up(md) {
    height:150px!important;
  }
}

.gform_legacy_markup_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
.gform_legacy_markup_wrapper textarea.large, .gform_legacy_markup_wrapper select.large {
  appearance: none;
  font-family: inherit;
  font-size: 18px;
  letter-spacing: normal;
  padding: 10px!important;
  border-radius:0px;
  border:solid 1px #ccc;
  background:#fff;
  color:$dark;
}
.gform_footer {
@extend .d-flex;
@extend .justify-content-start;
}
.gform_legacy_markup_wrapper .gform_footer input[type=submit] {
  width:auto!important;
}

#gform_submit_button_2 {
  width: 100%;
}

.gform-body {
  .gfield {
    .gform-field-label {
      color: $brand-primary !important;;
    }
    input, textarea {
      background-color: rgba($dark,.7) !important;
      border-radius: $rounded-border !important;
      border: 2px solid $primary !important;
      color: $light !important;
    }
  }
}