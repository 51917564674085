// Style 1
.sticky-top {
  position: sticky;
}
.home .trans-nav.trans-top {
  top: -160px;
  opacity: 0;
  background-color: transparent;
  transition: $transition-slow;
}
.home .trans-nav.trans-scrolling {
  top: 0;
  opacity: 1;
  transition: $transition-slow;
}
.trans-nav {
  width:100%;
  position:fixed;
  top:0;
  z-index:100;

  &.trans-top {
    .header-default {
      background-color: $medium;
      .navbar-nav {
        .nav-link {
          color: inherit;
          &:hover {
            color:$brand-primary;
          }
        }
        .nav-item {
          a {
            color: inherit;
          }
          i,svg {
            color:inherit;
          }
        }
      }
    }
  }
}
.shrink-on-scroll {
  &.nav-shrink {
    .header-default {
      .theme-logo {
        transition: all 100ms linear;
        max-height:30px;
        @include media-breakpoint-up(lg) {
          max-height:50px;
        }
      }
    }
  }
}
.header-default {
  background-color: $dark;
  top: 0;
  box-shadow: unset;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 10001;
  width: 100%;
  flex-wrap: nowrap;
  transition: $transition-slow;

  .theme-logo {
    max-height:40px;
    @include media-breakpoint-up(lg) {
      max-height:75px;
    }
    width:auto;
    transition:all 350ms ease-in-out;
    &:hover {
      opacity: 1;
    }
    @include media-breakpoint-up(lg) {
      max-width: 100%;
    }
  }
  .navbar-nav .nav-link {
    color: inherit;
    transition-duration: 350ms;
    font-family: $menu-font;
    outline: none !important;
    padding: 15px 18px;
    font-weight: $medium-weight;
    font-size: 1rem;
    position: relative;
    &:before {
      content: '';
      width: 5px;
      height: 5px;
      background-color: currentColor;
      background: linear-gradient(to right, $brand-primary, $brand-primary-offset);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      transition: $transition-fast !important;
      transform: translateY(-.5rem);
      transform-origin: left center;
      border-radius: 100%;
      pointer-events: none;
      opacity: 0;
    }
    &:hover,
    &:focus {
      opacity: 0.8;
      color: $brand-primary;
      &:before {
        transform: translateY(0rem);
        opacity: 1;
      }
    }
    @include media-breakpoint-up(lg) {
      padding-left: 1.15rem;
      padding-right: 1.15rem;
      font-size: 1rem;
    }
    &.dropdown-toggle::after {
      display: none;
    }
  }
  .navbar-nav .active .nav-link,
  .navbar-nav .active .nav-link:focus {
    color: inherit;
  }
  .navbar-toggler {
    border: none;
    outline: none !important;
    transition: all 0.2s ease-in;
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(156,204,80)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
      &:focus {
        box-shadow:unset;
      }
    }
  }
  .dropdown.menu-item-has-children {
    @include media-breakpoint-down(xl) {
      a {
        padding-left: 15px;
      }
    }
  }
  .dropdown-menu {
    width: 100% !important;
    border: none;
    margin-top: 0px;
    transform: none !important;
    min-width: 16rem;
    padding: 0px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    border-radius: 0 !important;
    @include media-breakpoint-up(xl) {
      position: absolute !important;
      display:block;
      box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.65);
      overflow: visible;
      top: 100% !important;
      transform: rotateX(-75deg);
      transform-origin: 0% 0% !important;
      left:0;
      padding: 4px;

      &.show {
        transition: 0.3s;
        opacity: 1;
        visibility: visible;
        top: 100% !important;
        transform: rotateX(0deg);
      }

    }
    .nav-item {
      border-bottom: 1px solid rgba($white,.025);
      padding: 0px;
      border-radius: $rounded-border !important;
      .dropdown-item {
        color: inherit;
        font-size: 1rem;
        padding: 0.75rem 1.5rem;
        transition: all 0.2s ease-in;
        font-weight: $medium-weight;
        white-space: normal;
        &:hover {
          color: $brand-primary;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  @include media-breakpoint-down(xl) {
    .navbar-collapse {
      .navbar-nav {
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 1rem;
        .nav-item {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
      .dropdown-menu {
        text-align: center;
        color: $brand-secondary !important;
        box-shadow: none;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .navbar-nav {
      .menu-item {
        &:hover {
          .dropdown-menu {
            transition: 0.3s;
            opacity: 1;
            visibility: visible;
            transform: rotateX(0deg);
          }
        }
      }
    }
  }
}

// 3rd Level Nav Desktop
@include media-breakpoint-up(xl) {
  .navbar-nav ul.dropdown-menu .dropdown ul.dropdown-menu {
    left: 100% !important;
    top: 0 !important;
    visibility: hidden;
    display: none;
    .dropdown-item {
      border-radius: $rounded-border !important;
    }
    &:before {
      transform: rotate(-90deg);
      top: 7px;
      left: -20px;
      border-bottom-color: $dark;
    }

  }
  .navbar-nav ul.dropdown-menu .dropdown:hover ul.dropdown-menu{
    visibility: visible;
    display: block !important;
  }
}

// 3rd Level Nav Mobile
@include media-breakpoint-down(xl) {
  ul.dropdown-menu.show .dropdown ul.dropdown-menu {
    display: block;
  }
}

header.sticky-top.scrolling .header-default.navbar-light {
  .theme-logo {
    max-height: 85px;
    transition: none;
    @include media-breakpoint-up(md) {
      max-height: 115px;
    }
  }
  .navbar-toggler {
    @include media-breakpoint-up(md) {
      top: 32px;
    }
  }
}
.header-top .sub-heading {
  @include media-breakpoint-down(xs) {
    font-size: 12px;
  }
}

.rpb-mega-menu {
  @include media-breakpoint-up(lg) {
    min-height: 350px;
  }
}