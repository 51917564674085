.site-footer {
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			padding-bottom:3px;
			&:last-of-type {
				padding-bottom:0;
			}
			a {
				color: $light;
				text-decoration: none;
				font-weight: normal;
			}
		}
	}

  .menu-title {
    letter-spacing: 4px;
    margin-bottom: 1rem;
  }
}

.social-icon {
  background: $brand-primary;
  align-items: center;
  border-radius: 3px;
  display: inline-flex;
  height: 1.5em;
  justify-content: center;
  width: 1.5em;
  transform:scale(1);
  svg {
    color:$white;
    font-size:.8em;
  }
  &:hover {
    transform:scale(1.05);
    svg {
      color:$dark;
    }
  }
}

.footer-dark {
	ul {
		li {
			a {
				color:white;
				&:hover {
					color:white;
					opacity:0.8;
				}
			}
		}
	}
}

.copyright-dark {
	background:darken($dark, 10%);
	a {
		color:white;
		&:hover {
			color:white;
			opacity:0.8;
		}
	}
}
