body {
  background: $medium;
  color: $white;
  overflow-x: hidden;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}


// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

//aos
[data-aos*=flip] {
  -webkit-backface-visibility:hidden!important;
}

/** custom classes */

.notunder1500 {
  .btn {
    width: 100%;
    border-radius: 0!important;
  }
}

.background-fixed {
  @include media-breakpoint-up(lg) {
    background-attachment: fixed !important;
  }
}

.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 66vw;

  @include media-breakpoint-up(xl) {
    min-height: 450px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}

// Images

img {
  max-width: 100%;
}

// Util
.img-invert-white {
  filter: brightness(0) invert(1);
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}
.z-index-2 {
  z-index:2;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 160px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}

.height-105vh {
  min-height: 650px;
  height: 105vh;
}

.height-100vh {
  min-height: 650px;
  height: 100vh;
}


.bottom-divider {
  background-image: url('data:image/svg+xml,<svg width="100%" height="100px" viewBox="0 0 1280 140" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><g fill="%23192828"><path d="M1280 140V0S993.46 140 640 139 0 0 0 0v140z"/></g></svg>');
  background-size: 100% 100px;
  bottom: 0;
  height: 100px;
  z-index: 1;
  transform: scale(1,1);
  position: absolute;
  pointer-events: none;
  width: 100%;
  left: 0;
  right: 0;
  display: block;
}

.background-attachment-fixed {
  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
  }
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;

  .divider-line {
    border-top-color: rgba($info,0.29);
    border-top-width: 2px;
    border-top-style: solid;
    margin: 0px;
    flex-grow: 1;
  }

  .sub-heading {
    flex-shrink: 0;
    letter-spacing: 3px;
    margin: 0 15px;
    flex-shrink: 1;
  }
}

.rounded {
  border-radius: $rounded-border !important;
}

.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center top;
  will-change: transform;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.nav-pills.border,
.blog-card {
  border: 2px solid rgba($brand-primary,.2) !important;
  &:hover {
    box-shadow: 0px 0px 0px 1px $brand-secondary !important;
  }
}

.nav-pills .nav-link {
  border: 0;
  border-radius: 50px;
  background-color: rgba($white,.1);
  margin: 1rem;
  &:hover {
    background-color: rgba($white,.4);
    color: $medium;
  }
  &.active {
    background-color: $brand-primary;
    color: $medium;
  }
}

.blog-pagination {
  display: flex;
  span {
    margin: .25rem;
    a {
      padding: 6px;
      border-radius: $rounded-border;
      background-color: $dark;
      &:hover {
        background-color: $brand-primary;
        color: $medium;
      }
    }
  }
}

#rmBreadcrumbs {
  display: flex;
  justify-content: flex-end;
  > span, > a {
    margin: .25rem;
    letter-spacing: 1px;
  }
  .separator {
    color: $info !important;
  }
}

.no-background-image-on-mobile {
  @include media-breakpoint-down(md) {
    background-image: unset !important;
  }
}

.bypass-page-header-image {
  background-image: unset !important;
}

.brand-line {
  height: 2px;
  background: $brand-primary;
  margin: 1em;
}

.three-col-list {
  column-count: 1;
  @include media-breakpoint-up(md) {
    column-count: 2;
  }
  @include media-breakpoint-up(xxl) {
    column-count: 3;
  }
}

.four-col-list {
  column-count: 1;
  @include media-breakpoint-up(md) {
    column-count: 2;
  }
  @include media-breakpoint-up(lg) {
    column-count: 3;
  }
  @include media-breakpoint-up(xxl) {
    column-count: 4;
  }
}