////////////////////////////////////////////////
// MAIN MENU EMBED
////////////////////////////////////////////////

// Menu Nav Edits

.headless-filter-navbar {
  //  background-color: rgba(0,0,0,.2) !important;
  background: transparent !important;
}

.headless-filter-navbar .headless-nav-cart-icon {
  z-index: 999;
}

.headless-filter-navbar .filter-navbar--desktop .navbar-left .headless-navbar-dropdown ul,
.headless-products-nav--sort .headless-sort-dropdown {
  background-color: var(--headless-primary) !important;
  color: var(--headless-dark) !important;
}

.headless-nav-cart-icon svg,
.headless-nav-cart-icon .headless-nav-cart-quantity {
  color: var(--headless-primary) !important;
  fill: var(--headless-primary) !important;
}

.headless-filter-navbar .filter-navbar--desktop .navbar-left .headless-navbar-dropdown ul li:hover {
  background-color: rgba(0, 0, 0, .2) !important;
}

.headless-filter-navbar .filter-navbar--desktop .headless-openorclosed-wrapper .headless-oc-option,
.headless-filter-navbar .filter-navbar--desktop .headless-pricingtype-select-wrapper {
  flex-direction: row;
  align-items: center;
}

.headless-products-nav .headless-products-nav-dropdown-btn,
.headless-filter-navbar .filter-navbar .headless-pricingtype-select-wrapper .headless-pricingtype-select {
  color: inherit;
}

.headless-filter-navbar .filter-navbar button,
.headless-searchbar .headless-nav-searchbar-input,
.headless-single-product .headless-sp-effects .headless-sp-effects-icons .headless-effect-box span {
  font-weight: $medium-weight !important;
  //font-size: 18px;
  letter-spacing: .5px;
}

.headless-searchbar .headless-nav-searchbar-input {
  //background: $brand-secondary !important;
  color: $dark !important;
  border-radius: $rounded-border !important;

  &::placeholder {
      color: $dark !important;
      opacity: 1;
      /* Firefox */
  }

  &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: $dark !important;
  }
}

.headless-products-nav--title {
  color: $brand-primary;

  span.headless-font-normal {
    // color: $brand-secondary !important;
  }
}

.headless-openorclosed-wrapper {
.headless-oc-option {
  display: inline-flex;
  align-items: center;
}
.headless-oc-name {
  margin-top: 0 !important;
  font-weight: $medium-weight !important;
  padding-left:6px;
}
}

.headless-products-nav .headless-products-nav-dropdown-btn {
border-width: 0 !important;
}

// Utility Edits

.headless-single-product .headless-breadcrumb,
.headless-single-product .headless-sp-category a {
  color: white;
}

.headless-button,
.headless-filter-sidebar .clear-checkbox-filters-button {
  background-color: var(--headless-primary) !important;
}

.headless-product-list-item .headless-button {
  background-color: var(--headless-primary) !important;
  color: $dark !important;

  &:hover {
      color: $dark !important;
  }
}

.headless-filter-sidebar .filter-sidebar-module .filter-sidebar-options {
  scrollbar-color: $brand-primary $dark !important;
  scrollbar-width: thin !important;
}

// Single Product
.single-product-pop {
  background-color: $dark !important;
  background: linear-gradient(to bottom, $medium, $dark) !important;
  border-radius: 0 !important;

}

.headless-product-effect-svg {
  height: 48px !important;
  fill: $brand-primary;
}

.headless-effects-icon {
  margin-bottom: 10px;
}

.headless-products-container .headless-product-card .headless-product-bottom .headless-add-to-cart .headless-cart-button {
  background-color: $dark !important;
}

.headless-sp-variation-price {
font-weight: $medium-weight !important;
}

.headless-sp-image {
border-radius: $rounded-border;
}

// Misc Layout

.headless-cart-icon .headless-cart-icon-qty {
  background-color: var(--headless-dark) !important;
  font-weight: $medium-weight !important;
}

.headless-button,
.headless-filter-sidebar .clear-checkbox-filters-button {
  font-weight: 300 !important;
}

.headless-product-list-item .headless-product-list-left .headless-product-list-info .headless-product-title a {
  font-weight: 400 !important;
  color: white;

  @include media-breakpoint-up(lg) {
      font-size: 22px;
      line-height: 26px;
  }
}

.headless-product-list-item .headless-product-list-left .headless-product-list-info .headless-product-brands {
  color: $brand-primary;
}

.headless-product-list-price-num {
  font-weight: 500 !important;
}

.headless-product-list-image {
  border-radius: $rounded-border !important;
}

.headless-list-info-details {
  margin-top: 10px !important;
  letter-spacing: .05rem !important;
}

.headless-list-category,
.headless-product-strain,
.headless-product-strain .headless-strain-icon {
  font-weight: 500 !important;
}

.headless-strain-icon.strain-sativa {
  color: $dark !important;
}

.headless-product-list-item {
  padding: 1em;
  border-radius: $rounded-border;
}

.headless-product-list-hr {
  background: transparent !important;
  margin: 7px 0;
}

.headless-products-nav {
  border-color: darken($dark, 10%);
}

.headless-filter-sidebar {
  border-right: 0;
}

.filter-sidebar-caret {
  fill: $light !important;
}

.filter-sidebar-module {
  background: rgba($black, .2);
  padding: 1em;
  width: 100%;
  border-radius: $rounded-border;
  margin-bottom: 1em;
  border-bottom: 0 !important;

  &:first-of-type {
      padding-top: 15px !important;
  }
}

@include media-breakpoint-up(lg) {
  .headless-filter-sidebar {
      padding-right: 0 !important;
      max-width: 300px !important;
  }
}


////////////////////////////////////////////////
// SLIDERS
////////////////////////////////////////////////

.headless-shortcode-title {
  text-transform: uppercase !important;
  font-weight: 300 !important;
  color: $brand-primary;
}

.headless-category-card {
  //background-color: var(--headless-dark) !important;
  background-color: transparent !important;
}

.headless-slider-next svg,
.headless-slider-prev svg {
  fill: var(--headless-primary) !important;
}

.headless-category-card {

  &:hover {
      box-shadow: unset !important;
      transform: scale(1.1) !important;
  }
}

.headless-products-container .headless-product-card {
  border-radius: $rounded-border !important;
}

.headless-products-container .headless-product-card .headless-product-image {
  padding: 0 !important;
  border-radius: $rounded-border !important;
}

// Main Nav Edits

#topLevelNav .headless-cart-icon {
  height: auto;
  width: auto;
  bottom: 0;
  left: 0;
  box-shadow: unset;
  flex-direction: row-reverse;
  border-radius: unset;

  .headless-cart-icon-qty {
      position: static;
      background: $brand-primary !important;
      color: $dark;
      margin: .2rem;
  }

  svg {
      margin: .2rem;
      fill: $brand-primary
  }

  &:hover {
      background-color: unset !important;
      filter: unset;
  }
}

// CART

.headless-cart-icon {
  background: white;

  svg {
      fill: $dark;
  }
}

#topLevelNav .headless-cart-icon {
  background: transparent !important;
}

.headless-cart-page .headless-cart-right {
  background-color: transparent !important;
  border-color: rgba($black, .3) !important;
  border-radius: $rounded-border !important;
}

.headless-cart-your-store a {
  font-size: 14px !important;
}

.headless-cart-sidebar-item {
  border-color: rgba($black, .2) !important;
  border-radius: $rounded-border;

  .headless-cart-product-top {
      background-color: darken($dark, 5%);
  }

  .headless-cart-product-image {
      padding: 10px;
  }

  .headless-cart-sidebar-table {
      background-color: darken($dark, 10%);
      padding: 10px 10px;
  }
}

.headless-cart-sidebar-item .headless-cart-product-top .headless-cart-product-title {
  font-weight: 300 !important;
}

.headless-cart-page .headless-cart-store-details .headless-cart-store-icon {
  border-radius: 100%;
}

.headless-cart-page .headless-cart-left {
  border-color: rgba($black, .3) !important;
  border-radius: $rounded-border !important;
}

// SIDEBAR

.headless-cart-sidebar {
  background-color: $dark !important;
}

.headless-cart-sidebar .headless-cart-sidebar-bottom .headless-cart-sidebar-totals,
.headless-cart-sidebar .headless-cart-sidebar-header {
  background-color: darken($dark, 10%);
  color: $brand-primary;
  font-weight: 500 !important;
}

.headless-cart-sidebar .headless-cart-sidebar-bottom .headless-cart-sidebar-checkout .headless-cart-button {
  background-color: $brand-primary !important;
  color: $dark !important;

  svg {
      fill: $dark !important;
  }
}

.headless-cart-sidebar.headless-show-sidebar {
  box-shadow: -250px 0 150px 25px rgba(0, 0, 0, .4) !important;
}


@include media-breakpoint-down(md) {
.headless-products-container .headless-product-card,
.category-section .blaze-container {
  border-radius: 0 !important;
}

.headless-slider-next, .headless-slider-prev {
  background-color: $dark !important;
}

.headless-slider-next svg, .headless-slider-prev svg {
  fill: $brand-primary !important;
}
}

.headless-cart-ordertype label,
.headless-cart-ordertype label svg {
  color: $light !important;
  fill: $light!important;
  opacity: .25!important;
  transition: $transition-med !important;
}

.headless-cart-ordertype label:hover {
  opacity: 1 !important;
  background: $medium !important;
  svg {
    opacity: 1 !important;
  }
}

.headless-cart-ordertype input[type=radio] {
  color: $white !important;
}

.headless-cart-ordertype input[type=radio]:checked~label {
  color: $primary !important;
  background: lighten($dark,10%) !important;
  opacity: 1 !important;
  svg {
    opacity: 1 !important;
  }
}

.headless-cart-ordertype label,
.headless-cart-ordertype {
  border-radius: $rounded-border !important;
}

// General
.headless-cart-icon {
  background-color: $brand-primary !important;
  box-shadow: 1px -1px 0px 1px $brand-secondary !important;
  border: 1px solid $brand-primary !important;
  svg {
    fill: $medium !important;
  }
}

// Product Feeds
.headless-product-card {
  border-radius: $rounded-border !important;
  background-color: $medium !important;
  border: 2px solid rgba($brand-primary,.2) !important;

  .headless-product-image {
    opacity: .9 !important;
    filter: grayscale(.3) !important;
    border-radius: $rounded-border !important;
    transition: $transition-fast;
  }

  .headless-product-title a {
    color: $info !important;
    text-shadow: -1px -1px 0em $brand-secondary !important;
    font-weight: 700 !important;
  }
}

.headless-product-card:hover {
  border-color: $brand-primary !important;
  box-shadow: 0px 0px 0px 1px $brand-secondary !important;
  transform: scale(1) !important;

  .headless-product-image {
    opacity: 1 !important;
    filter: brightness(1.1) !important;
    transform: scale(1.1);
  }
}

@include media-breakpoint-up(md) {
  .headless-slider-next svg, .headless-slider-prev svg {
    fill: $info !important;
  }
}

// Shop Menu
.headless-filter-navbar {
  background-color: transparent !important;

//   .navbar-left > div:not(.headless-searchbar) button {
//     background: rgba($white,.2) !important;
//     border-radius: $rounded-border !important;
//     padding: 0 20px 1px 10px !important;
//     line-height: 1.6 !important;
//     color: $white !important;

//     &.filter-navbar-dropdown-btn:before {
//         background-image: url('data:image/svg+xml,<svg width=%2717.5%27 height=%2715%27 xmlns=%27http://www.w3.org/2000/svg%27><path d=%27M3 5L8.75 12L14.5 5%27 stroke=%27%23fff%27 fill=%27none%27 stroke-width=%272%27/></svg>');
//       right: 7px;
//     }
//   }
}

.headless-searchbar {
  background: transparent !important;

  .headless-nav-searchbar-input {
    padding: 2px 10px !important;
  }
  .headless-nav-searchbar-input::placeholder {
    color: $light !important;
    opacity: 1;
  }
  .headless-nav-searchbar-input:active,
  .headless-nav-searchbar-input:focus {
    &::placeholder{
      color: $dark !important;
    }
  }

  .clear-search-icon {
    right: 1.1em !important;
    svg {
      height: 1em !important;
      width: 1em !important;
    }
  }
}

.headless-searchbar:before {
  right: 5px !important;
  left: auto !important;
  display: none!important;
}


.headless-menu-category-cards {
  .headless-menu-builder-title {
    display: none !important;
  }
  .headless-category-card {
    background: transparent !important;
  }

}

.headless-filter-navbar .filter-navbar--desktop .headless-openorclosed-wrapper .headless-oc-option, .headless-filter-navbar .filter-navbar--desktop .headless-pricingtype-select-wrapper {
  flex-direction: column !important;
}

.headless-cart-sidebar .headless-cart-sidebar-bottom .headless-cart-sidebar-totals,
.headless-cart-sidebar .headless-cart-sidebar-header {
  background-color: rgba($black,.4) !important;
}
