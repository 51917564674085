/*
** font sizes calculated in perfect fourths using https://type-scale.com/
*/
// set type
$menu-font: 'Manrope', Helvetica, Arial, sans-serif;
$heading-font: 'Alfa Slab One', Helvetica, Arial, sans-serif;
$subheading-font: 'Manrope', Helvetica, Arial, sans-serif;
$paragraph-font: 'Manrope', Helvetica, Arial, sans-serif; // set weights
$light-weight: 300;
$normal-weight: 400;
$medium-weight: 600;
$heavy-weight: 700;
$ultra-weight: 900;

body {
  font-size: 14px;
  line-height: 1.7em;
  font-weight: 500;
  color: $body-font;
  font-family: $paragraph-font;
  @include media-breakpoint-up(md) {
    font-size: 15px;
  }
}

a {
  color: $brand-primary;
  font-weight: $heavy-weight;
  text-decoration: none;
  transition-duration: 350ms;
  -webkit-transition-duration: 350ms;
  -moz-transition-duration: 350ms;

  &:hover {
    text-decoration: none;
    color: $brand-secondary;
  }
}

.bold,
strong {
  font-weight: 700;
}

.sub-heading {
  font-size: 1.25rem;
  font-family: $subheading-font;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: $normal-weight;
}

.display-1,
.display-2,
.display-3 {
  font-family: $heading-font;
}

.h1,
h1 {
  font-size: 2.369rem;
  @include media-breakpoint-up(lg) {
    font-size: 3.157rem;
  }
}

.h2,
h2 {
  font-size: 1.777rem;
  @include media-breakpoint-up(lg) {
    font-size: 2.369rem;
  }
}

.h3,
h3 {
  font-size: 1.333rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.777rem;
  }
}

.h4,
h4 {
  font-size: 1rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.333rem;
  }
}

.h5,
h5 {
  font-size: 1rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}
// Line height

.reduce-line-height {
  line-height: 1 !important;
}

// Glitch Text Effect
.glitch-text-wrap {
  position: relative;
  //overflow: hidden;
}

.glitch-text {
  position: relative;
  * {
    line-height: 1;
    word-break: break-word;
  }
}

.glitch-text>:after, .glitch-text>:before {
  content: attr(data-text);
  text-align: inherit;
  width: 100%;
  position: absolute;
  top: 0;
  opacity: .8;
}

.glitch-animation>:before {
  left: 0;
  z-index: -1;
  color: $accent;
  animation: glitch_animation .5s cubic-bezier(.25,.46,.45,.94) both infinite;
}

.glitch-animation>:after {
  right: 0;
  z-index: -2;
  color: $accent-2;
  animation: glitch_animation .3s cubic-bezier(.25,.46,.45,.94) reverse both infinite;
}

@keyframes glitch_animation {
  0% { transform: translate(0); }
  20% { transform: translate(-3px) skewX(5deg);}
  40% { transform: translate(-3px); }
  60% { transform: translate(3px); }
  80% { transform: translate(3px); }
  100% { transform: translate(0); }
}

.small-paragraph > * {
  @include media-breakpoint-up(sm) {
    max-width: 340px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.medium-paragraph > * {
  @include media-breakpoint-up(sm) {
    max-width: 340px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  @include media-breakpoint-up(md) {
    max-width: 500px;
  }
}

.increase-letter-spacing {
  letter-spacing: 3px;
}

.increase-line-height {
  line-height: 1.8;
}