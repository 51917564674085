// Default Slider Styles
.range-splider {
  .splide__slider {
    .rpb-slider-arrows {
      .splide__arrow {
        background:transparent;
        opacity:1;
        transform: scale(2);
        box-shadow: unset !important;
        svg {
          fill:$info;
          color:$info;
        }
      }
      .splide__arrow--prev {
        svg {
          transform:none;
        }
      }
      .splide__arrow--next {
        svg {
          transform:none;
        }
      }
    }
  }
  .splide__pagination {
    @include media-breakpoint-down(md) {
      display:none;
    }
    .splide__pagination__page.is-active {
      background:$brand-primary;
    }
  }
  &.blurred-slides {
    .splide__slide {
      &:before {
        content:'';
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background:rgba(0,0,0,0.5);
        z-index: 1;
        backdrop-filter: saturate(180%) blur(2px);
        transition:all ease-in-out 350ms;
        opacity:1;
      }
      &.is-active {
        &:before {
          content:'';
          opacity:0;
        }
      }
    }
  }
}

// Column Content Slider
.range-content-slider {
  .splide__slider {
    .rpb-slider-arrows {
      .splide__arrow--prev {
        left:0;
      }
      .splide__arrow--next {
        right:0;
      }
    }
    .splide__track {
      margin-left:3rem;
      margin-right:3rem;
    }
    .splide__pagination {
      bottom:-2.5rem;
    }
  }
}

// Coupon Slider Styles
.rpb-coupon-slider {
  .splide__slider {
    .rpb-slider-arrows {
      .splide__arrow--prev {
        left:0;
      }
      .splide__arrow--next {
        right:0;
      }
    }
    .splide__track {
      margin-left:3rem;
      margin-right:3rem;
    }
    .splide__pagination {
      bottom:-2.5rem;
    }
  }
}

// Thumbnail Slider
.range-splider-thumbnails {
  .splide__track {
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    background:white;
    .splide__list {
      .splide__slide {
        opacity:0.3;
        &.is-active {
          border:0!important;
          opacity:1;
        }
      }
    }
  }
}

// Logo Banner Slider
.rpb-logo-slider {
  .splide__slider {
    .rpb-slider-arrows {
      .splide__arrow--prev {
        left:0;
      }
      .splide__arrow--next {
        right:0;
      }
    }
    .splide__track {
      margin-left:3rem;
      margin-right:3rem;
    }
  }
}